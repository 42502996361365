import type { FC } from 'react';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import Markdown from 'markdown-to-jsx';
import { defineMessages, useIntl } from 'react-intl-next';

import { Box, Stack, xcss } from '@atlaskit/primitives';
import { FlagsProvider } from '@atlaskit/flag';
import Heading from '@atlaskit/heading';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';

import { useAIEventsInstrumentation } from '@atlassian/ai-analytics';
import { AISkeletonLoading, AIBorder, AISummaryLoading } from '@atlassian/ai-components';

import { useSessionData } from '@confluence/session-data';
import {
	COMMENTS_SUMMARY_EXPERIENCE,
	ExperienceSuccess,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { CommentCountsContext } from '@confluence/comment-context';
import {
	AISummaryFooter,
	FeedbackCollectorPropertiesProvider,
	SummaryPopupScrollableBody,
	type SummaryAnalyticsPropertiesType,
	CommentsSummaryAnalyticsType,
} from '@confluence/quick-summary';
import { fg } from '@confluence/feature-gating';
import { mergeLiveDocI18n } from '@confluence/live-pages-utils/entry-points/mergeLiveDocI18n';

import { useCombinedCommentsSummariesQueries } from './hooks/useCombinedCommentsSummariesQueries';
import { FOOTER_COMMENTS_SUMMARY_FEEDBACK_ENTRYPOINT_ID } from './feedbackEntrypointIds';
import { SummaryType } from './__types__/CommentsSummaryQuery';
import { COMMENTS_SUMMARY_INTERACTION_METRIC } from './perf.config';
import { CommentsSummariesError } from './CommentsSummariesErrors';

const styles = xcss({ height: '100%' });
const i18nBase = defineMessages({
	pageCommentsHeader: {
		id: 'ai-comments-summary.combined-comments-summary.page-comments-header',
		defaultMessage: 'Page comments',
		description:
			'Section header content for page comments summary inside the comments summary component',
	},
	inlineCommentsHeader: {
		id: 'ai-comments-summary.combined-comments-summary.inline-comments-header',
		defaultMessage: 'Inline comments',
		description:
			'Section header content for inline comments summary inside the comments summary component',
	},
});

const i18nLiveDocs = defineMessages({
	pageCommentsHeader: {
		id: 'ai-comments-summary.combined-comments-summary.page-comments-header.livedocs',
		defaultMessage: 'General comments',
		description:
			'Updated version of ai-comments-summary.combined-comments-summary.page-comments-header',
	},
});

const i18n = mergeLiveDocI18n(i18nBase, i18nLiveDocs);

export enum CommentsSummarySource {
	Footer = 'footer',
	Header = 'header',
	Feed = 'feed',
}

type CommentsSummaryContentProps = {
	contentId: string;
	contentType: string;
	spaceKey: string;
	onRefresh?: () => void;
	shouldRefetchCommentsSummary?: boolean;
	source: CommentsSummarySource;
	isInObjectSidebar?: boolean;
};

export const CommentsSummaryContent: FC<CommentsSummaryContentProps> = ({
	contentId,
	contentType,
	spaceKey,
	onRefresh,
	shouldRefetchCommentsSummary = false,
	source,
	isInObjectSidebar,
}) => {
	const intl = useIntl();
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { edition } = useSessionData();
	const { footerCommentsCount, unresolvedInlineCommentsCount } = useContext(CommentCountsContext);

	const { trackAIInteractionInit, trackAIResultView, trackAIInteractionDismiss } =
		useAIEventsInstrumentation();

	const contentTypeEnum = contentType === 'page' ? SummaryType.PAGE : SummaryType.BLOGPOST;

	const { data, errors, loading, refetch } = useCombinedCommentsSummariesQueries({
		contentId,
		contentType: contentTypeEnum,
		shouldSkipFooterCommentsQuery: footerCommentsCount === 0,
		shouldSkipInlineCommentsQuery: unresolvedInlineCommentsCount === 0,
	});

	const refreshHandler = () => {
		if (!loading) {
			refetch();
			onRefresh?.();
		}
	};

	const startExperienceTracking = useCallback(() => {
		experienceTracker.start({
			name: COMMENTS_SUMMARY_EXPERIENCE,
			attributes: {
				contentId,
				source,
			},
		});

		COMMENTS_SUMMARY_INTERACTION_METRIC.start();
	}, [contentId, experienceTracker, source]);

	useEffect(() => {
		if (loading) {
			startExperienceTracking();
		}
		if (!loading) {
			COMMENTS_SUMMARY_INTERACTION_METRIC.stop();
		}
	}, [loading, startExperienceTracking]);

	const footerCommentsSummary = data.footerCommentsSummaryData?.getCommentsSummary?.summary ?? '';
	const inlineCommentsSummary = data.inlineCommentsSummaryData?.getCommentsSummary?.summary ?? '';

	const loadingRef = useRef(loading);
	useEffect(() => {
		loadingRef.current = loading;
	}, [loading]);

	useEffect(() => {
		trackAIInteractionInit();

		if (shouldRefetchCommentsSummary) {
			refetch();
			onRefresh?.();
		}
		return () => {
			if (loadingRef.current) {
				trackAIInteractionDismiss();
			}
		};
		// Only check auto refetch on mount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const summaryLocale = useMemo(() => {
		// Ok to just choose first of whichever is available from data team
		return (
			data.footerCommentsSummaryData?.getCommentsSummary?.language ||
			data.inlineCommentsSummaryData?.getCommentsSummary?.language
		);
	}, [data.footerCommentsSummaryData, data.inlineCommentsSummaryData]);

	const commentsType = useMemo(() => {
		const commentsTypeCollection: Array<CommentsSummaryAnalyticsType> = [];
		if (data.footerCommentsSummaryData)
			commentsTypeCollection.push(CommentsSummaryAnalyticsType.FOOTER);
		if (data.inlineCommentsSummaryData)
			commentsTypeCollection.push(CommentsSummaryAnalyticsType.INLINE);
		return commentsTypeCollection;
	}, [data.footerCommentsSummaryData, data.inlineCommentsSummaryData]);

	const feedbackContext = {
		commentsType,
		source,
	};

	const analyticsProperties: SummaryAnalyticsPropertiesType = {
		source: 'commentsSummary',
		additionalAnalyticsAttributes: {
			commentsType,
		},
	};

	useEffect(() => {
		if (!loading) {
			if (!errors.footerCommentsSummaryError || !errors.inlineCommentsSummaryError) {
				createAnalyticsEvent({
					type: 'sendScreenEvent',
					data: {
						name: 'commentsSummaryContainer',
						attributes: {
							contentId,
							edition,
							commentsType,
							summaryLocale,
							numPageCommentsSummarized: footerCommentsCount,
							numInlineCommentsSummarized: unresolvedInlineCommentsCount,
						},
					},
				}).fire();

				trackAIResultView();
			}
		}
	}, [
		createAnalyticsEvent,
		contentId,
		edition,
		loading,
		commentsType,
		summaryLocale,
		errors.footerCommentsSummaryError,
		errors.inlineCommentsSummaryError,
		footerCommentsCount,
		trackAIResultView,
		unresolvedInlineCommentsCount,
	]);

	const fullTextToCopy = () => {
		return `${
			footerCommentsSummary &&
			`${intl.formatMessage(i18n.pageCommentsHeader)}\n${footerCommentsSummary}`
		}\n\n${
			inlineCommentsSummary &&
			`${intl.formatMessage(i18n.inlineCommentsHeader)}\n${inlineCommentsSummary}`
		}`;
	};

	const renderContentBody = () => {
		return (
			<Stack space="space.200">
				{inlineCommentsSummary && (
					<Stack space="space.100">
						<Heading size="small">{intl.formatMessage(i18n.inlineCommentsHeader)}</Heading>
						<Markdown>{inlineCommentsSummary}</Markdown>
					</Stack>
				)}
				{footerCommentsSummary && (
					<Stack space="space.100">
						<Heading size="small">{intl.formatMessage(i18n.pageCommentsHeader)}</Heading>
						<Markdown>{footerCommentsSummary}</Markdown>
					</Stack>
				)}
				{(!errors.footerCommentsSummaryError || !errors.inlineCommentsSummaryError) && (
					<ExperienceSuccess
						name={COMMENTS_SUMMARY_EXPERIENCE}
						attributes={{ contentId, source }}
					/>
				)}
				{(errors.footerCommentsSummaryError || errors.inlineCommentsSummaryError) && (
					<CommentsSummariesError
						contentId={contentId}
						footerCommentsError={errors.footerCommentsSummaryError}
						inlineCommentsError={errors.inlineCommentsSummaryError}
						source={source}
					/>
				)}
			</Stack>
		);
	};

	const renderContent = () => {
		if (loading) {
			return isInObjectSidebar ? <AISummaryLoading /> : <AISkeletonLoading />;
		}

		const shouldShowFooterError =
			(!!errors.footerCommentsSummaryError && !!errors.inlineCommentsSummaryError) ||
			(!!errors.footerCommentsSummaryError && inlineCommentsSummary.length === 0) ||
			(!!errors.inlineCommentsSummaryError && footerCommentsSummary.length === 0);

		return (
			<Stack xcss={styles} spread="space-between">
				<Box padding="space.250">
					{source !== CommentsSummarySource.Footer ? (
						<SummaryPopupScrollableBody data-testid="scrollable-comments-summary">
							{renderContentBody()}
						</SummaryPopupScrollableBody>
					) : (
						renderContentBody()
					)}
				</Box>
				<AISummaryFooter
					analyticsProperties={analyticsProperties}
					contentId={contentId}
					contentType={contentType}
					spaceKey={spaceKey}
					error={shouldShowFooterError}
					summaryText={fullTextToCopy()}
					showBetaLozenge
					shouldHighlightRefresh={shouldRefetchCommentsSummary}
					refreshHandler={refreshHandler}
				/>
			</Stack>
		);
	};

	return (
		<FlagsProvider>
			<FeedbackCollectorPropertiesProvider
				entrypointId={FOOTER_COMMENTS_SUMMARY_FEEDBACK_ENTRYPOINT_ID}
				feedbackContext={feedbackContext}
			>
				<UFOLoadHold
					name="comments-summary-content"
					hold={fg('ufo-manual-experimental-holds-confluence') && loading}
				/>
				{isInObjectSidebar ? (
					<>{renderContent()}</>
				) : (
					<AIBorder loading={loading}>{renderContent()}</AIBorder>
				)}
			</FeedbackCollectorPropertiesProvider>
		</FlagsProvider>
	);
};
